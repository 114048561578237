import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import QRCode from 'qrcode.react';
import { refreshTrackingPage } from '../../../utils/refresh-tracking-page';
import TrackingV2 from '../../../utils/tracking-v2';
import TrackingV3 from '../../../utils/tracking-v3';
import { ErrorMessage } from '../../error-message';
import { Loader } from '../../shared/loader';
import { styles } from './style';
export class BankIdQR extends PureComponent {
    constructor(props) {
        super(props);
        this.canPoll = true;
        this.state = {
            qr: null,
            error: false,
        };
        this.pollStatus = this.pollStatus.bind(this);
    }
    componentDidMount() {
        const { onContinue, canContinueChanged } = this.props;
        this.getQR();
        canContinueChanged(true);
        onContinue((next) => next());
    }
    componentWillUnmount() {
        this.canPoll = false;
    }
    pollStatus(retry = 0) {
        const { orderToken, onNext } = this.props;
        // to keep the component from polling when unmounting back
        if (!this.canPoll) {
            return;
        }
        if (retry > 3) {
            this.setState({ error: true });
            return;
        }
        TrackingV3()
            .identificationStatus(orderToken)
            .then((response) => {
            if (!this.canPoll) {
                return;
            }
            if (response.status === ResponseStatus.COMPLETED) {
                const { status, hint } = response.payload;
                if (status === 'COMPLETED') {
                    refreshTrackingPage();
                    onNext();
                }
                else if (status === 'PENDING') {
                    this.pollStatus(retry + 1);
                }
                else if (status === 'FAILED' && hint === 'TIMED_OUT') {
                    this.getQR();
                }
                else {
                    this.setState({ error: true });
                }
            }
            else {
                // request failed, slow enough or unauthenticated call
                this.setState({ error: true });
            }
        })
            .catch(() => {
            setTimeout(() => this.pollStatus(retry + 1), 1000);
        });
    }
    getQR() {
        const { orderToken } = this.props;
        TrackingV2()
            .identifyAction(orderToken)
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                const { qr, criipto } = response.payload;
                if (criipto && criipto.authorizationUrl) {
                    window.location.href = criipto.authorizationUrl;
                    return;
                }
                this.setState({ qr });
                this.pollStatus();
            }
            else {
                this.setState({ error: true });
            }
        });
    }
    renderQR() {
        const { qr, error } = this.state;
        if (error) {
            return (_jsx("div", { className: css(styles.divider), children: _jsx(ErrorMessage, { message: this.props.intl.formatMessage({
                        id: 'bankIdTryLater',
                    }), positionVariant: "inline" }) }));
        }
        if (!qr) {
            return _jsx(Loader, { variant: "inline", style: styles.loader });
        }
        return _jsx(QRCode, { size: 220, level: "L", value: qr });
    }
    render() {
        return (_jsxs("div", { className: css(styles.container), children: [_jsx("span", { className: css(styles.header), children: this.props.intl.formatMessage({
                        id: 'identifyWithBankIDHeader',
                    }) }), this.state.qr && !this.state.error && (_jsx("p", { className: css(styles.subtitle), children: this.props.intl.formatMessage({
                        id: 'openBankIdAndScanQRCode',
                    }) })), this.renderQR()] }));
    }
}
