import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BoxParcelStatus } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { useLockerAttributes } from '../../state/box-order/hooks';
import { useSelectedLocker } from '../../state/order/hooks';
import { BoxOrderLocation } from '../box-order-location';
import { BoxParcelJourney } from '../box-parcel-journey';
import { PinCode } from '../pin-code';
import { View } from '../shared/view';
import { Title } from '../title';
import { styles } from '../box-content/style';
export function ReturnToLockerBoxContent({ merchantName, merchantLogo, toggleLocationModal, status, code, }) {
    const { t } = useI18n();
    const locker = useSelectedLocker();
    const lockerAttributes = useLockerAttributes();
    const { entryAccessCode, address } = lockerAttributes || {};
    const showBoxInfo = locker && status === BoxParcelStatus.NotStarted;
    const locationName = getLocationName(address);
    return (_jsxs(_Fragment, { children: [code && (_jsxs(View, { style: styles.spacing, children: [_jsx(Title, { style: styles.pinTitle, title: t('yourBoxPinCode') }), _jsx(PinCode, { code: code, entryAccessCode: entryAccessCode, locationName: locationName })] })), _jsx(BoxParcelJourney, { style: styles.spacing }), !!locker && (_jsx(View, { style: styles.spacing, children: _jsx(BoxOrderLocation, { lockerName: locker.lockerAddress.name, merchantName: merchantName, logo: merchantLogo, openHours: locker.openHours, toggleLocationModal: () => toggleLocationModal(), hideLocationInfo: !showBoxInfo, isReturn: true }) }))] }));
}
function getLocationName(address) {
    return [address === null || address === void 0 ? void 0 : address.name, address === null || address === void 0 ? void 0 : address.street].filter(Boolean).join(', ');
}
