const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};
export const latitude = (obj) => {
    if ('latitude' in obj) {
        return obj.latitude;
    }
    if ('lat' in obj && isFunction(obj.lat)) {
        return obj.lat();
    }
    if ('lat' in obj) {
        return obj.lat;
    }
    if ('getLngLat' in obj) {
        return obj.getLngLat().lat;
    }
    return null;
};
export const longitude = (obj) => {
    if ('longitude' in obj) {
        return obj.longitude;
    }
    if ('lng' in obj && isFunction(obj.lng)) {
        return obj.lng();
    }
    if ('lng' in obj) {
        return obj.lng;
    }
    if ('getLngLat' in obj) {
        return obj.getLngLat().lng;
    }
    return null;
};
export const stdLatLng = (lat, lng) => {
    return { latitude: lat, longitude: lng };
};
export const stdCoordinate = (obj) => {
    return { latitude: latitude(obj), longitude: longitude(obj) };
};
export const mapboxCoordinate = (coordinate) => [
    longitude(coordinate),
    latitude(coordinate),
];
/**
   Elementwise addition of LatLng vectors
*/
export const add = (a, b) => {
    return stdLatLng(latitude(a) + latitude(b), longitude(a) + longitude(b));
};
/**
   Elementwie subtraction of LatLng vectors
*/
export const subtract = (a, b) => {
    return stdLatLng(latitude(a) - latitude(b), longitude(a) - longitude(b));
};
/**
   Elemenwise multiplication of the LatLng vector v with the scalar s
*/
export const mulScalar = (v, s) => {
    return stdLatLng(latitude(v) * s, longitude(v) * s);
};
function distance(lat1, lon1, lat2, lon2) {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist * 1.609344;
}
/**
    Calculates the spherical distance bettwen two vectors
*/
export const sphericalDistance = (from, to) => {
    return distance(latitude(from), longitude(from), latitude(to), longitude(to)) * 1000;
};
function toRadians(degrees) {
    return (degrees * Math.PI) / 180;
}
// Converts from radians to degrees.
function toDegrees(radians) {
    return (radians * 180) / Math.PI;
}
function bearing(startLat, startLng, destLat, destLng) {
    const startLatRadians = toRadians(startLat);
    const startLngRadians = toRadians(startLng);
    const destLatRadians = toRadians(destLat);
    const destLngRadians = toRadians(destLng);
    const y = Math.sin(destLngRadians - startLngRadians) * Math.cos(destLatRadians);
    const x = Math.cos(startLatRadians) * Math.sin(destLatRadians) -
        Math.sin(startLatRadians) *
            Math.cos(destLatRadians) *
            Math.cos(destLngRadians - startLngRadians);
    let brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
}
/**
   Calculate the angle in degrees between the LatLng vector formed by
   LatLng point from and to and the vector going from `from` to the
   north pole.
*/
export const heading = (from, to) => {
    return bearing(latitude(from), longitude(from), latitude(to), longitude(to));
};
/**
    Calculates the bounds needed to capture the LatLng
    vectors a and b
*/
export const bounds2 = (a, b) => {
    const latMax = Math.max(latitude(a), latitude(b));
    const latMin = Math.min(latitude(a), latitude(b));
    const lngMax = Math.max(longitude(a), longitude(b));
    const lngMin = Math.min(longitude(a), longitude(b));
    return [
        [lngMin, latMin],
        [lngMax, latMax],
    ];
};
export const isValidLtnLngPair = (coordinates) => {
    return coordinates.every((v) => v && !Number.isNaN(v));
};
export const isValidBounds = (coordinatePairs) => {
    return coordinatePairs.every((value) => isValidLtnLngPair(value));
};
