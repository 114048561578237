import { ConsignmentType, ParcelStatus, eta as etaUtils, } from '@budbee/js/dist/tracking';
import { useI18n } from '../../../hooks/use-i18n';
import { useEta } from '../../../state/eta/hooks';
import { useCountryCode, useLanguage, useTimeZone } from '../../../state/locale/hooks';
import { useConspectus, useRoutedEta } from '../../../state/order/hooks';
const showEta = ({ conspectus, etaText, }) => {
    if (!conspectus) {
        return null;
    }
    if (conspectus.cancellation) {
        return null;
    }
    const { status, identification, consignment } = conspectus;
    if (status.state === ParcelStatus.Collected ||
        status.state === ParcelStatus.OnRouteDelivery ||
        status.state === ParcelStatus.CollectedShippingLabel ||
        status.state === ParcelStatus.CrossDocked) {
        return etaText;
    }
    if ((status.state === ParcelStatus.NotStarted || status.state === ParcelStatus.Backordered) &&
        consignment) {
        if (consignment.cancellation) {
            return null;
        }
        if (identification && identification.needsVerification) {
            return null;
        }
        return etaText;
    }
    return null;
};
export const useIsDelayed = () => {
    var _a;
    const eta = useEta();
    const routedEta = useRoutedEta();
    const etaTime = (eta === null || eta === void 0 ? void 0 : eta.date) || ((_a = etaUtils.fromRoutedEta(routedEta)) === null || _a === void 0 ? void 0 : _a.date);
    if (etaTime === null || etaTime === undefined)
        return false;
    const currentTime = new Date();
    return currentTime > etaTime;
};
const useEtaText = () => {
    const routedEta = useRoutedEta();
    const language = useLanguage();
    const timeZone = useTimeZone();
    const countryCode = useCountryCode();
    const eta = useEta();
    const { t, i18n } = useI18n();
    const conspectus = useConspectus();
    const { status, consignment, address } = conspectus || {};
    const isReturn = (consignment === null || consignment === void 0 ? void 0 : consignment.type) === ConsignmentType.RETURN;
    const isDelayed = useIsDelayed();
    if (isDelayed && consignment && timeZone) {
        return t('homeOrder.deliveryBeforeEndOfWindow', {
            stop: i18n.formatDateAndTime(consignment.stop, countryCode, language, timeZone),
        });
    }
    if (timeZone && address) {
        return i18n.etaText(eta || etaUtils.fromRoutedEta(routedEta), status === null || status === void 0 ? void 0 : status.state, isReturn, consignment || null, address.countryCode, language, timeZone);
    }
    return null;
};
export const useEtaHeader = () => {
    const conspectus = useConspectus();
    const etaText = useEtaText();
    return showEta({ etaText, conspectus });
};
